import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import Page from "../components/page"
import Wrap from "../components/wrap"
import Renderer from "../components/renderer"

const TemplateInstructor = ({ data: { mdx } }) => {
  const { body, frontmatter } = mdx
  const {
    name,
    title,
    genre,
    official_hp,
    official_blog,
    facebook,
    twitter,
    instagram,
    soundcloud,
    mixcloud,
    iflyer,
    photo,
  } = frontmatter
  return (
    <Page title={name}>
      <Wrap>
        <InstructorProfile>
          <div className="photo">
            <Img fluid={photo.childImageSharp.fluid} />
          </div>
          <div className="body">
            <h2 className="name">{name}</h2>
            {title && (
              <p className="title">
                <span>{title}</span>
              </p>
            )}
            {genre && (
              <div className="genre">
                <span>{genre}</span>
              </div>
            )}
            <div className="description">
              <Renderer>{body}</Renderer>
            </div>
            {(official_hp ||
              official_blog ||
              facebook ||
              twitter ||
              instagram ||
              soundcloud ||
              mixcloud ||
              iflyer) && (
              <div className="links">
                <h3>Links</h3>
                <ul>
                  {official_hp && (
                    <li>
                      <a
                        href={official_hp}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Official HP
                      </a>
                    </li>
                  )}
                  {official_blog && (
                    <li>
                      <a
                        href={official_blog}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Official Blog
                      </a>
                    </li>
                  )}
                  {facebook && (
                    <li>
                      <a
                        href={facebook}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Facebook
                      </a>
                    </li>
                  )}
                  {twitter && (
                    <li>
                      <a
                        href={twitter}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Twitter
                      </a>
                    </li>
                  )}
                  {instagram && (
                    <li>
                      <a
                        href={instagram}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Instagram
                      </a>
                    </li>
                  )}
                  {soundcloud && (
                    <li>
                      <a
                        href={soundcloud}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        SoundCloud
                      </a>
                    </li>
                  )}
                  {mixcloud && (
                    <li>
                      <a
                        href={mixcloud}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        MixCloud
                      </a>
                    </li>
                  )}
                  {iflyer && (
                    <li>
                      <a
                        href={iflyer}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        iFlyer
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </InstructorProfile>
      </Wrap>
    </Page>
  )
}

export default TemplateInstructor

export const pageQuery = graphql`
  query PageQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        name
        title
        genre
        official_hp
        official_blog
        facebook
        twitter
        instagram
        soundcloud
        mixcloud
        iflyer
        photo {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

const InstructorProfile = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin: 2rem 0;

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }

  .photo {
  }
  .body {
  }
  .name {
    margin: 0;
  }
  .title {
    font-size: 0.75rem;
    margin: 0 0 1rem;

    span {
    }
  }
  .genre {
    margin: 1rem 0;
    span {
      display: inline-block;
      padding: 0.25em 1em;
      background: ${({ theme }) => theme.yellow};
    }
  }
  .description {
    p {
      margin: 0 0 1rem;
    }
  }
  .message {
    position: relative;
    background: #f1f1f1;
    margin: 4rem 0 1rem;
    padding: 1em;

    &::before {
      content: "Message";
      position: absolute;
      bottom: calc(100% + 0.5rem);
      left: 0;
      font-size: 1.5rem;
    }

    p {
      margin: 0 0 1rem;

      &:last-child {
        margin: 0;
      }
    }
  }
  .links {
    ul {
      margin: 1rem 0;
      padding: 0;
      list-style: none;
    }
  }
`
